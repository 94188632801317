import React from "react";
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CarouselModal from "../../ItemList/Item/CarouselModal";

const ItemPrice = ({price, salePrice, curr}) => 
    <div className={style.ItemPrice}>
        <NumberFormat value={price} prefix={curr} decimalScale={2} fixedDecimalScale={true} displayType={'text'} 
          style={{textDecorationLine: price === salePrice ? 'none' : 'line-through', marginLeft: '6px'}} />
        { price === salePrice ||  
           <span><NumberFormat value={salePrice} prefix={curr} decimalScale={2} fixedDecimalScale={true} displayType={'text'}/> </span> 
        }            
    </div>

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})
@observer
class CartItem extends React.Component {
  constructor(props) {
    super(props)
    
    this.inputRef = React.createRef();
    this.state = {
       comment: '',
       carouselModalIsOpen: false
    }
  }

  handleCommentChange = () => {
    if(this.inputRef.current){
      this.props.cartItem.comment = this.inputRef.current.value;
    }
  }

  openCarouselModal = () => {
    this.setState({carouselModalIsOpen: true});
  }

  closeCarouselModal = () => {
      this.setState({carouselModalIsOpen: false});
  }

  render() {
    const { removeCartItem, openCartItemForm, getCartItemPrice,
            mainColor, shop, shopData } = this.props.myShopApiStore;
    const { cartItem, index, handleCheckboxChange, t } = this.props;
    const product = cartItem.item.product ? cartItem.item.product : null;

    const { currencyName } = shopData.country;

    const iFlags = [
      ...['isNew', 'isSale', 'isSeason'].filter((flag) => cartItem.item[flag] === true),
      ...[cartItem.item.promotions?.length ? cartItem.item.promotions[0].name : undefined ].filter((flag) => flag !== undefined),
    ];
    const hasProductOption = cartItem.item.shopProductOptions ? cartItem.item.shopProductOptions.length : false;
    const isEditableItem = hasProductOption || cartItem.item.shopToppings.length || cartItem.item.shopBaseToppings?.length; 
    const isCartPackItem = cartItem.item.product.productType === "pack";
    const { imageBig } = cartItem.item.product;
    const { isRTL } = this.props.ifaceMngr;
    
    return (
      <div className={style.Container}>
        { !imageBig?.length || <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={cartItem.item}/> }
        <div className={style.RightElements}>
            <div className={style.ItemCheckbox}>
              <input
                    type="checkbox" 
                    checked={cartItem.isReadyToOrder}
                    onChange={(e) => handleCheckboxChange(e, cartItem.id)}
              />
            </div>
        </div>
        <div className={style.MidElements}>
          <div className={style.MidUpElements}>
            <div className={style.MidRightElements}>
              <div className={style.ImagFlagDetalisWrapper}>
                <div className={style.ImageAndFlag}>
                  <LazyLoadImage
                    effect='blur'
                    className={style.ItemImage}
                    src={product ? product.image : ""}
                    alt={product ? product.description : ""}
                    onClick={this.openCarouselModal}
                  />
                  <div className={style.ItemFlags}>
                    { iFlags.map((flag, index) => 
                        <div key={index} className={style.iFlags} 
                          style={{backgroundColor: ['isNew', 'isSeason'].includes(flag) ? '#FF8000' : '#E10000'}}>
                            {t(flag)}
                          </div>) }
                  </div>
                </div>
                <div className={style.ItemDetails} style={{ textAlign: document.body.dir === 'rtl' ? 'right' : 'left', [ document.body.dir === 'rtl' ? 'marginRight' : 'marginLeft'] : '1vw'}}>
                  <div className={style.ItemName} title={cartItem.item.product.name} style={{color: mainColor}}>{cartItem.item.product.name}</div>
                  {
                    !shop.isBusiness
                    && <ItemPrice price={getCartItemPrice(cartItem)} salePrice={getCartItemPrice(cartItem, true)} curr={ currencyName }/>
                  }
                  <div className={style.ItemQuantity}>
                    {cartItem.quantity} {t(cartItem.unitType?.type || cartItem.item.unitTypes[0].type)}
                  </div>
                </div>
              </div>
            </div>
            <div className={style.MidLeftElements}>
              <div className={style.MoreInfo}>
                <div className={style.MoreInfoTooltip} style={{color: mainColor}}>
                  {cartItem.item.product.description && 
                      <>
                        <div className={style.label} data-tip={cartItem.item.product.description} data-for={`moreInfo_${cartItem.id}`}
                        >
                          {t('More info')}
                        </div>
                        <ReactTooltip 
                          className={style.Tooltip}
                          place={ isRTL ? 'right' : 'left' }
                          multiline={true}
                          data-html={true}
                          insecure={true} 
                          id={`moreInfo_${cartItem.id}`} 
                          type='light' 
                          border={true} 
                          effect='solid' 
                        />
                      </>
                  }
                </div>
              </div>
              <div className={style.Buttons}>
                <div className={style.EditButton}>
                  {(isEditableItem || isCartPackItem) ? (
                    <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => openCartItemForm(index, isCartPackItem, true)}>
                      {t('UpdateProduct')}
                    </button>
                  ) : null}
                </div>
                <div className={style.RemoveButton}>
                  <button style={{backgroundColor: mainColor, border: `1px solid ${mainColor}`}} onClick={() => removeCartItem(index)}>
                    {t('remove')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={style.Comment}>
                <div className={style.Title} onClick={() => {this.inputRef.current && this.inputRef.current.focus()}}>
                  {t('toppingNote')}
                </div>
                <div className={style.InputText}>
                  <input type='text' value={cartItem.comment} ref={this.inputRef} onChange={this.handleCommentChange}/>
                </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CartItem);
