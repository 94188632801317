import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import NumberFormat from "react-number-format";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import sad from '../../../../../../assets/sad.png';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class MinOrderCostModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                // height: '290px',
                overflow: 'hidden'
                // maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    render() {
        const { modalIsOpen, closeModal, t } = this.props;
        const { shop, shopData } = this.props.myShopApiStore;
        
        const { currencyName } = shopData.country;


        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.SadImg}>
                            <LazyLoadImage effect='blur' src={sad} width='80px'/>
                        </div>
                        <div className={style.Title}>
                            <span>{t('Oops')}...</span>
                        </div>
                        <div className={style.ThreeRowText}>
                            <span>{t('you have already reached the minimum')}</span>
                            <span>{t('required for ordering')}</span>
                            <span>{t('a little more and we are there')}</span>
                        </div>
                    </div>
                    <div className={style.ContinueBtn}>
                       <p onClick={closeModal}>{t('Minimum order size is')}:&nbsp;<NumberFormat
                                value={shop.minimalOrder}
                                prefix={currencyName}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={"text"}
                            />
                      </p>
                    </div>

                </div>
            </Modal>
        )
    }
}

export default withTranslation()(MinOrderCostModal);