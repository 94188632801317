import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import NumberFormat from 'react-number-format';
import style from './style.module.css';
import CarouselModal from 'Components/MainScreen/AppBody/ShopMenu/ItemList/Item/CarouselModal';

const getItemTotal = (item) => 
        item.total +
        item.toppings.reduce((acc, topping) => acc + topping.total, 0) +
        (item.productOption?.total ?? 0) +
        item.baseToppings?.reduce((acc, baseTopping) => acc + baseTopping.total, 0) +
        (!item.isPack ? 0 
            : item.levels.reduce((acc, level) => acc + 
                level.products.reduce((acc, product) => acc + getItemTotal(product), 0), 0));

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class Item extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            carouselModalIsOpen: false
        }
    }

    closeCarouselModal = () => {
        this.setState({carouselModalIsOpen: false})
    }

    openCarouselModal = () => {
        this.setState({carouselModalIsOpen: true})
    }

    render() {
        const { shop, shopData } = this.props.myShopApiStore;
        const { item, t } = this.props;

        const { currencyName } = shopData.country;

        const imageBig = item.shopProduct ? item.shopProduct.product.imageBig : null;
        return (
            <div className={style.Container}>
                <div className={style.modals}>
                    {
                        imageBig && typeof imageBig !== 'string' && imageBig.length && <CarouselModal modalIsOpen={this.state.carouselModalIsOpen} closeModal={this.closeCarouselModal} item={item.shopProduct}/>
                    }
                </div>
                <div className={style.RightDiv}>
                    <div className={style.Image}><img src={item.shopProduct.product.image} alt='' /></div>
                    {/* <div className={style.Tag}>טאגג</div> */}
                </div>
                <div className={style.CenterDiv}>
                    <div className={style.Name} >{item.shopProduct.product.name}</div>
                    <div className={style.CenterBottomDiv}>
                        {   !shop.withoutPrices && 
                            <div className={style.ItemCost}> 
                                <NumberFormat value={ item.price } prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={'text'} /> 
                                {' ' + t('per')} {t(item.unitType)}
                            </div>
                        }
                        { !!item.comment && <div className={style.Comment}>{`${t('comment')} ${item.comment}`}</div> }
                    </div>
                </div>
                <div className={style.LeftDiv}>
                    <div className={style.Amount}>{item.amount} {t(item.unitType)}</div>
                    <div className={style.TotalCost}>{
                        !shop.withoutPrices && 
                        <NumberFormat value={getItemTotal(item)} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={'text'} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Item);