import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Basket from './Basket';
import HistItem from './HistItem';
import Empty from "../Empty";
import Spinner from 'Components/Spinner';
import { LocationModal } from './CurierLocation';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})
@observer
class StoredHistory extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             interval: null,
             times: null,
             loading: false,
             isCourierVisible: false,
        }
    }

    handleScroll = async e => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const { getOrderHistoryResp, ordersHistory } = this.props.myShopApiStore;

        if(!ordersHistory.length || this.state.loading) return;
        // console.log(e)
        // console.log('scrollHeight:', e.target.scrollHeight);
        // console.log('scrollTop:', Math.floor(e.target.scrollTop));
        // console.log('clientHeight:', e.target.clientHeight);
        
        if(scrollHeight - Math.floor(scrollTop) === clientHeight ||
                scrollHeight - Math.ceil(scrollTop) === clientHeight){
            this.setState({loading: true})
            await getOrderHistoryResp()
            this.setState({loading: false})
        }
    }

    componentDidMount = async () => {
        const { ordersHistory, getOrderHistoryResp } = this.props.myShopApiStore;

        if(!ordersHistory?.length && !this.state.loading){
            this.setState({loading: true})
            await getOrderHistoryResp()
            this.setState({loading: false})
        }   
    }

    componentWillUnmount = () => {
        this.state.loading && this.setState({loading: false})
    }

    getShippingStatus = (orderId) => {
        let shippingStatus = null;
        this.state.times && this.state.times.forEach(time => {
            if(orderId === time.orderId){
                shippingStatus = {...time};
            }
        })
        return shippingStatus;
    }

    render() {
        const { ordersHistory, basketHistory } = this.props.myShopApiStore;
        const { isMobile } = this.props;
        
        return (
            <div className={style.Container}>
                {!this.state.loading && !ordersHistory?.length && <Empty hideBasket={this.props.hideBasket}/> }

                <section className={style.HistList} onScroll={this.handleScroll}>
                    {
                        !!ordersHistory?.length && (!isMobile || !this.props.basketVisible) 
                        &&
                            ordersHistory.map((order, idx) => 
                                <React.Fragment key={idx}> 
                                    <HistItem order={order}
                                        isFirstOrder={!idx}
                                        showBasket={this.props.showBasket}
                                        basketVisible={this.props.basketVisible}
                                        isCourierVisible = {this.state.isCourierVisible}
                                        showCourier={(show = !this.state.isCourierVisible) => { 
                                            this.setState({...this.state, isCourierVisible: show});
                                        }}
                                        
                                    />
                                </React.Fragment>)
                    }
                    <Spinner loader='BeatLoader' loading={this.state.loading} />
                </section>
                {
                    (!!ordersHistory?.length && (!isMobile || this.props.basketVisible))
                    && 
                        // <section className={style.contBasket}>
                            <Basket hideBasket={this.props.hideBasket} isCourierVisible={this.state.isCourierVisible}/>
                        // </section>
                }
                <LocationModal 
                    order={basketHistory} 
                    modalIsOpen={this.state.isCourierVisible} 
                    closeModal={() => this.setState({...this.state, isCourierVisible: false})}
                    />
            </div>
        )
    }
}

export default StoredHistory;
