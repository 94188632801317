import React, {useEffect, useRef, useState} from "react";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import default_topping from '../../../../../../../assets/toppings/default_topping.png'
import pizza_tmp from '../../../../../../../assets/toppings/pizza_tmp.jpg'
import MaxToppingsMsg from "../MaxToppingsMsg";
//PIZZA
const SubForm3 = ({
  currentStep,
  currencyName,
  handleChange,
  shopToppings,
  selectedToppings,
  selectedProductOption,
  toppingsDescription,
  toggleToppingOnQuarter,
  toggleToppingOnAllQuarters,
  removeTopping,
  optionsPaid,
  hasError,
  toppingsAddPaid,
  toppingsFree,
  hasProductOptions,
  maxToppings,
  closeMaxToppingMsg,
  mainColor,
  withoutPrices
}) => {

  const [toppingToEdit, setToppingToEdit] = useState(null);
  const requestToppingImage = require.context('../../../../../../.././assets/toppings', true, /.png$/);
  const requestToppingPosImage = require.context('../../../../../../.././assets/topping_positions', true, /.png$/);
  const { t } = useTranslation();
  const scrollUpRef = useRef(null);

  useEffect(() => {
      scrollUpRef?.current?.scrollIntoView({behavior: 'smooth'})
  },[toppingToEdit])


  useEffect(() => {
    if(toppingToEdit){ //clear 'topping to edit' when user remove this topping.
        if(!selectedToppings.some(topping => topping.id == toppingToEdit.id)){
            setToppingToEdit(null);
        }
    }
    if(selectedToppings.length){ //Marks the next edit topping to the pizza
        setToppingToEdit(selectedToppings[selectedToppings.length-1]);
    }
  }, [selectedToppings])



 const getToppingCost = (topping) => {
   let price = 0;
   if(toppingsFree){ //calc topping quarters price (for pack item)
      const quartersFree = toppingsFree * 4;
      let quartersCount = 0; // number of toppings quarters

      selectedToppings.forEach(t => {
          t.quarterList.forEach(q => {
            if(t.id === topping.id){
              price += (quartersCount < quartersFree  && q)
                          ?
                              0
                          :
                              q
                              ?
                                  t.price/4
                              : 
                                  0;
            }
            quartersCount += q ? 1 : 0;
          })
      })
    }
   else{
     topping.quarterList.forEach(inQuarter => {
       if(inQuarter){
         price += (topping.price / 4)
       }
     })
   }
  return (
    price ?
      (<NumberFormat value={price} prefix={currencyName} decimalScale={2} fixedDecimalScale={true} displayType={"text"} />) : (t('free'))
  );
 }

 const getToppingPosImageName = (topping) => {
  const topLeft = topping.quarterList[0] ? 1 : 0;
  const topRight = topping.quarterList[1] ? 1 : 0;
  const bottomLeft = topping.quarterList[2] ? 1 : 0;
  const bottomRight = topping.quarterList[3] ? 1 : 0;

  return `./t_${topLeft}${topRight}${bottomLeft}${bottomRight}.png`;
 }

 const displayPizzaToppingCost = (shopTopping) => {
   let price = 0;
   let quartersCount = 0; // number of toppings quarters
   const quartersFree = toppingsFree * 4;

   if(toppingsFree){
    selectedToppings.forEach(t => {
        t.quarterList.forEach(q => {
          if(t.id === shopTopping.id){
            price += (quartersCount < quartersFree  && q)
                        ? 0 : (q ? t.price/4 : 0);
          }
          quartersCount += q ? 1 : 0;
        })
    })
   }
   
   const isToppingOnPizza = selectedToppings.some(t => t.id === shopTopping.id && t.quarterList.some(q => q))
   
   return(
    !shopTopping.price || (toppingsFree && (!price && ((isToppingOnPizza) || quartersCount < quartersFree)))
    ?
      t('free')
    :
      <NumberFormat
        value={shopTopping.price}
        prefix={currencyName}
        decimalScale={2}
        fixedDecimalScale={true}
        displayType={"text"}
      />     
   )
 }

 

  return (
    <div className={style.Container} >
      
      <div className={style.ToppingsDescription} ref={scrollUpRef}>{toppingsDescription}<br/>{t("You can select quarters by clicking on the picture")}</div>

      {/* {
        hasLimitedError &&
          <div className={style.MaxToppingsError}>{'** שים לב! כמות תוספות מוגבלת **'}</div>
      } */}
      <div className={style.PizzaContainer} >

        <div className={style.RightSideTabs}>
          <div className={style.SelectedProductOption} style={{ backgroundColor: mainColor, opacity: .9}}>
            <div className={style.NameProductOption}>{selectedProductOption.name}</div>
            {
              (hasProductOptions && !withoutPrices)
              ?
                <div className={style.PriceProductOption}>
                  {(selectedProductOption.price && optionsPaid !== 0) ?
                    (<NumberFormat 
                      value={selectedProductOption.price}
                      prefix={currencyName}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      />
                    ) : (t('free'))}
                </div>
              :
                ''
            }
          </div>
          <div className={style.SelectedToppings}>
              {
                  selectedToppings.map(topping =>
                  {
                    if(topping.quarterList.some(q => q === true))
                    {
                      return(
                        <div className={style.ToppingToEdit}
                          title={topping.id}
                          key={topping.id}
                          style={{backgroundColor: (toppingToEdit && toppingToEdit.id == topping.id) ? mainColor : `${mainColor}A5`}}
                          onClick={() => setToppingToEdit(topping)}>
                            <div className={style.ToppingNameAndPrice}>
                              <div className={style.ToppingName}><span className={style.ToppingXRemover} onClick={() => removeTopping(topping)}>✕</span>{topping.name}</div>
                              <div className={style.ToppingPrice}>{!withoutPrices && getToppingCost(topping)}</div>
                            </div>
                            <div className={style.ToppingImgPositions}>
                              <LazyLoadImage effect='blur' src={requestToppingPosImage(getToppingPosImageName(topping)).default} width="68%" />
                              {/* <LazyLoadImage effect='blur' src={t_0000} width='68%' /> */}
                            </div>
                        </div>
                      )
                    }
                  })
                  
              }
          </div>
        </div>
        <div className={style.PizzaIcon}>
            {/* <LazyLoadImage effect='blur' src={requestToppingImage(`./pizza.png`).default} width="230px" /> */}
            <LazyLoadImage effect='blur' src={pizza_tmp} width="230"/>

            <div className={style.PizzaCenter} onClick={() => toggleToppingOnAllQuarters(toppingToEdit)}/>

            { ['TopLeftQuarter','TopRightQuarter','BottomLeftQuarter','BottomRightQuarter']
                .map((item, _idx)=>
                  <div className={style[item]} key={_idx} onClick={() => toggleToppingOnQuarter(toppingToEdit, _idx)}>
                    {selectedToppings.length
                    ? selectedToppings.map(topping=> {return topping.quarterList[_idx]
                      ? <LazyLoadImage effect='black-and-white' key={`${topping.id}-Q${_idx}`} src={topping.codeName ? requestToppingImage(`./${topping.codeName}.png`).default : default_topping } />
                      :null})
                    :null}
                  </div>
                ) 
            }
        </div>
        <div className={style.ChoiceIcon}>
            {selectedToppings.length ? <LazyLoadImage effect='blur' src={requestToppingImage(`./choice.png`).default} width="240px"/> : ""}
        </div>
      </div>

      <div className={style.ItemToppingList}>
        { hasError && <MaxToppingsMsg closeMaxToppingMsg={closeMaxToppingMsg}
                                      limitedToppingsNumber={(toppingsAddPaid===0 && maxToppings > toppingsFree)
                                                          || maxToppings === null || maxToppings === 0 ? toppingsFree : maxToppings}/> }
        {
          shopToppings.map((st, idx) => {
            return (
              <div key={idx} className={style.ItemTopping}>
                <div className={style.WrapBtnAndName}>
                  <input
                    type="checkbox"
                    value={st.id}
                    name="selectedToppings"
                    checked={selectedToppings.some(topping => topping.id == st.id)}
                    onChange={(e)=> handleChange(e)}
                  />
                  <span className={style.ItemToppingName}>{st.topping.name}</span>
                </div>
                <span className={style.ItemToppingPrice}>
                  {!withoutPrices && displayPizzaToppingCost(st)}
                </span>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default SubForm3;
