import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Item from './Item';
import Spinner from 'Components/Spinner';
import CategoryTime from './CategoryTime';
import WorkingTimeSign from './WorkingTimeSign';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class ItemList extends React.Component {
    constructor(props) {
        super(props)
        this.containerRef = React.createRef()
        this.state = { workingTimePanel: null, prevCategory: null }
    }
    

    //////////////yaroslav code-----------------
    // componentDidMount = () => {
    //     window.addEventListener('resize', this.checkLoader);
    // }

    // componentWillUnmount = () => {
    //     window.removeEventListener('resize', this.checkLoader);
    // }
    /////////////////-------------------------------

    componentDidMount() {
        const { setSearchValue, setSubcatItemList } = this.props.myShopApiStore;
        setSubcatItemList(null);

        //setSearchValue('', true);
    }

    componentDidUpdate(prevProps) {
        const { selectedCategoryCode } = this.props.myShopApiStore;
        // to the top of the list when category is changed
        if (this.state.prevCategory !== selectedCategoryCode) {
            this.containerRef.current.scrollTop = 0;
            this.setState({ prevCategory: selectedCategoryCode });
        }

    }

    // componentWillUnmount = () => {
    //     const { setSubcatItemList } = this.props.myShopApiStore;
    //     setSubcatItemList(null);
    // }

    checkLoader = (e) => {
        if (this.props.myShopApiStore.categoryPageCount > 0 && this.props.myShopApiStore.categoryPageCount > this.props.myShopApiStore.categoryPage)
        {
            const scrollTop = this.containerRef.current.scrollTop;
            const clientHeight = this.containerRef.current.clientHeight;
            if(clientHeight - scrollTop < 300) {
                this.props.myShopApiStore.getItemList(this.props.myShopApiStore.selectedCategoryIdx, false, this.props.myShopApiStore.selectedSubCategoryCodename);
            }
        }
    }

    render() {
        const { itemListAll, suggestions, searchValue, categoryPageCount, subcatItemList, selectedCategoryCode, categories, categoryPage, isProductsLoading, isSearchingProducts, shop, shopId, getCoupon, mainColor, selCategory, hasCategoryTimeLimit, workingDeliveryPickupSign
        } = this.props.myShopApiStore;
        const { t } = this.props;
        const loading = (
            (!searchValue && ((categoryPageCount > 0 && categoryPageCount > categoryPage) || isProductsLoading))) || 
            (searchValue && isSearchingProducts);
           
        let itemList = (itemListAll && itemListAll[shopId] && itemListAll[shopId][selectedCategoryCode]) ? itemListAll[shopId][selectedCategoryCode] : null;

        if (!itemList?.length && selectedCategoryCode) console.log(`Check the category ${selectedCategoryCode}`);

        if(!categories || categories.length == 0) itemList = [];

        return (
            <div className={style.Container} ref={this.containerRef} onScroll={this.checkLoader}>
                <div className={style.TopDiv}>
                    {
                        searchValue
                        ?
                            suggestions.length
                            ?
                                suggestions.map((item, index) => {
                                    this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                    
                                    return (
                                        <Item item={item} index={index} key={`p_${index}_${item.id}`} toggleBasket={this.props.toggleBasket}/>
                                    )
                                })
                            :
                                null
                        :
                            <React.Fragment>
                                <WorkingTimeSign shop={ this.props.myShopApiStore.shop } 
                                    country={ this.props.myShopApiStore.shopData?.country }
                                    itemStyle={ style.whContainer }/>       
                            
                                { hasCategoryTimeLimit ? <CategoryTime category = { selCategory } mainColor = { mainColor }/>:null}
                                { itemList?(subcatItemList??itemList)
                                    .map((item, index) => {
                                        this.props.myShopApiStore.setMaxSideMenuIdx(index);
                                        return (
                                            <Item item={item} index={index} key={`p_${index}_${item.id}`} toggleBasket={this.props.toggleBasket}/>
                                        )
                                    }):null   
                                }
                            </React.Fragment>
                    }
                    {
                        searchValue && !suggestions.length && !isSearchingProducts
                        ?
                            <div className={style.Oops}>
                                <div className={style.title}>{t("Oops")}...</div>
                                <div className={style.subtitle}>{t("No results were found for the search term you entered")}</div>
                                <div className={style.subtitle}>{t('Try expanding the item description')}</div>
                            </div>
                        :
                            null
                    }

                    <div className={loading ? style.LoaderContainer : ''}>
                        <Spinner loader='BeatLoader' loading={loading} color={this.props.myShopApiStore.mainColor}/>
                    </div>
                </div>
                {
                    !shop.isBusiness && shop.withCoupons && !getCoupon()
                    ?
                        <div style={{backgroundColor: mainColor}} className={style.couponMobile} onClick={() => {this.props.toggleBasket(); this.props.myShopApiStore.setCouponPreopened(true)}}>
                            {t("Have a coupon?")}
                        </div>
                    :
                        null
                }
            </div>
        )
    }
}

export default withTranslation()(ItemList);