import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import Modal from 'react-modal';
import bgWelcomeMap from '../../../assets/bgWelcome';
import { withTranslation } from 'react-i18next';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})
@observer
class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                margin: 0,
                padding: 0,
                background: `url(${bgWelcomeMap[props.myShopApiStore.mainColor] ?? bgWelcomeMap['#292929']}) no-repeat`,
                backgroundSize: '100%',
                backgroundPosition: 'top',
                backgroundColor: '#FFFF',
                maxHeight: '100%',
                // width: '30%',
                
                width: '450px',
                height: '243px',
                overflow: 'hidden',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    goMenu = () => {
        const { setActiveTopMenu } = this.props.ifaceMngr;
        const { closeModal } = this.props;
        
        setActiveTopMenu("ShopMenu");
        closeModal();
    }
    render() {
        const { modalIsOpen } = this.props;
        const { shop } = this.props.myShopApiStore;
        const { isRTL } = this.props.ifaceMngr;
        const { t } = this.props;
        return (
            <Modal style={ this.modalStyle } isOpen={ modalIsOpen } onRequestClose={ this.goMenu } ariaHideApp={false}>
                <div className={style.ModalContainer}>
                    <h3>
                        {(isRTL || !isRTL) && shop && t('WelcomeTo', {shop})}
                    </h3>
                    <button type="button" onClick={ this.goMenu}>
                        {t('GoToMenu')}
                    </button>
                </div>
            </Modal>
        )
    }
}
export default withTranslation()(WelcomeModal);