import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import MinOrderCostModal from "./MinOrderCostModal";
import ChangeAddressModal from './ChangeAddressModal'
import MakeOrderErrorModal from "../MakeOrderErrorModal";
import ChangeOrderTypeModal from "./ChangeOrderTypeModal";
import APILoader from "../../APILoader";
import  { timeView, dateView} from 'utils/utils';

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class ShippingOptionForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: [],
            minOrderCostModalIsOpen: false,
            changeAddressModalIsOpen: false,
            changeOrderTypeModalOpen: false,
            tryChangeTypeOrderTo: '',
            makeOrderErrorModalIsOpen: false,
            makeOrderErrorModalErrorCode: '',
            makeOrderErrorModalErrorMessage: '',
            apiLoaderIsOpen: false,
            selectedTimeIdx: '',
            selectedBranchIdx: ''
        }
    }
    
    
    componentDidMount = async () => {
        this.setState({
            errors: [],
            selectedTimeIdx: '',
            selectedBranchIdx: ''
        });

        const { orderData, resetOrderData } = this.props.myShopApiStore;
        resetOrderData();
        this.props.setShippingOption(orderData.isDelivery);
    }

    componentDidUpdate() {
        const { isDelivery } = this.props.myShopApiStore.orderData;
        this.props.setShippingOption(isDelivery);
    }

    closeMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: false})
    }

    openMinOrderCostModal = () => {
        this.setState({minOrderCostModalIsOpen: true})
    }

    closeChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: false})
    }

    openChangeAddressModal = () => {
        this.setState({changeAddressModalIsOpen: true})
    }

    openChangeTypeOrderModal = () => {
        this.setState({changeOrderTypeModalOpen: true});
    }

    closeChangeTypeOrderModal = () => {
        this.setState({changeOrderTypeModalOpen: false});
    }

    handleNextSubmit = async (e) => {
        e.preventDefault();
        const { orderData, setDeliveryCost,
                calcTotalCartPrice, shop,
                cartPrice, userToken, userParams,
                checkTime } = this.props.myShopApiStore;

        const errors = [];

        if(orderData.isDelivery){
            if(orderData.deliveryData.date === '' && !shop.withoutFuture_delivery) {
                if(this.state.selectedTimeIdx !='') { // Fix if we are returning to this page
                    this.handleChange({target:{name:'deliveryTime', value:this.state.selectedTimeIdx}});
                }
            }
            if(orderData.deliveryData.date === '' && !shop.withoutFuture_delivery) {
                errors.push('deliveryTime')
            }
        }
        else{
            if(orderData.deliveryData.date === '' && !shop.withoutFuture_delivery) {
                if(this.state.selectedTimeIdx !='') { // Fix if we are returning to this page
                    this.handleChange({target:{name:'pickupTime', value:this.state.selectedTimeIdx}});
                }
            }
            if(orderData.pickupData.date === '' && !shop.withoutFuture_pickup) {
                errors.push('pickupTime')
            }
        }

        if(shop.branches && shop.branches.length) {
            if(orderData.branch === '' && orderData.orderType !== 'delivery'){
                if(this.state.selectedBranchIdx !='') { // Fix if we are returning to this page
                    this.handleChange({target:{name:'branch', value:this.state.selectedBranchIdx}});
                }
            }
            if(orderData.branch === '' && orderData.orderType !== 'delivery') {
                errors.push('branch')
            }
        }

        if(!errors.length){

            this.openAPILoader()
            const allowed_time = await checkTime();
            this.closeAPILoader()

            if(allowed_time.errorCode == '0') {
                //reset delivery cost when user pickup order
                if(!orderData.isDelivery){
                    setDeliveryCost(0);
                    calcTotalCartPrice();

                    this.props.nextStep(); 
                }
                else{ //checks minimal cost to order (only for delivery order)
                    if(shop.minimalOrder > cartPrice){
                        this.openMinOrderCostModal();
                    }
                    else{
                        // if(userToken && userParams.city){
                        //     this.openChangeAddressModal();
                        // }
                        // else{
                            this.props.nextStep();
                        // }
                    }
                }
            } else {
                this.setMakeOrderErrorModalParams(allowed_time.errorCode, allowed_time.errorMessage);
                this.openMakeOrderErrorModal();
            }
        }
        else{
            this.setState({
                errors
            })
        }
    }

    closeMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: false})
    }

    openMakeOrderErrorModal = () => {
        this.setState({makeOrderErrorModalIsOpen: true})
    }

    setMakeOrderErrorModalParams = (makeOrderErrorModalErrorCode, makeOrderErrorModalErrorMessage) => {
        this.setState({
            makeOrderErrorModalErrorCode: makeOrderErrorModalErrorCode,
            makeOrderErrorModalErrorMessage: makeOrderErrorModalErrorMessage
        })
    }

    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    // getFormatDate = date => {
    //     const myDate = new Date(date * 1000);
    //     const myFormatedDate = myDate.toLocaleDateString('he-IL');

    //     return myFormatedDate;
    // }

    getFormatTime = (date=null, from ,to) => {
        const { isDelivery, deliveryData, pickupData } = this.props.myShopApiStore.orderData;
        const { timeFormat, dateFormat } = this.props.myShopApiStore.shopData.country ?? {};
        //date = date || isDelivery?deliveryData.date:null || pickupData.date;
        let curData = date?{date, from, to}:null || isDelivery?deliveryData:pickupData;

        //const locale = country?.alfa2Code ? `${country.defaultLang}-${country.alfa2Code}` : 'he-IL';

        return `${dateView(curData.date, dateFormat)} ${timeView(curData.from, timeFormat)}-${timeView(curData.to, timeFormat)}`;
    }

    canOrderedNow = () => {
        const { shop, orderData } = this.props.myShopApiStore;

        const days = {
            1: 'sunday',
            2: 'monday',
            3: 'tuesday',
            4: 'wednesday',
            5: 'thursday',
            6: 'friday',
            7: 'saturday'
        }
        
        const currDate = new Date();
        
        const currIntDay = currDate.getDay();
        const currHour = currDate.getHours();
        const currMin = currDate.getMinutes();

        // const times = orderData.isDelivery ? shop.deliveryTimes : shop.pickupTimes;
        const times = shop.workingTimes;
        for(let i=0 ; i<times.length ; i++){
            const strDay = times.weekday;
            const from = times.from;
            const to = times.to;

            const intHHFrom = parseInt(from.slice(0, 2));
            const intMMFrom = parseInt(from.slice(-2));
            const intHHTo = parseInt(to.slice(0, 2));
            const intMMTo = parseInt(to.slice(-2));
            
            if(days[currIntDay] === strDay){
                if(currHour === intHHFrom){
                    if(currMin >= intMMFrom){
                        if(currHour === intHHTo){
                            if(currMin <= intMMTo){
                                return true;
                            }
                        }
                        else if(currHour < intHHTo){
                            return true;
                        }
                    }
                }
                else if(currHour > intHHFrom){
                    if(currHour === intHHTo){
                        if(currMin <= intMMTo){
                            return true;
                        }
                    }
                    else if(currHour < intHHTo){
                        return true;
                    }
                }
            }
        }

        return false;
    }

    // cleanTime = () => {
    //     this.setState({
    //         selectedTimeIdx: ''
    //     });
    // }

    changeOrderType = (toType) => {
        const { setOrderData, resetOrderData, shop} = this.props.myShopApiStore;
        this.setState({selectedTimeIdx: ''}); 
        // resetOrderData(); 
        // setOrderData('isDelivery', toType === 'delivery'); 
        setOrderData('orderType', toType);
        if (shop.isPriceOrderType)  { 
            this.props.ifaceMngr.setActiveTopMenu('ShopMenu'); 
            // this.props.myShopApiStore.clearReadyToOrderCartItems();
            //this.props.myShopApiStore.
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;  /// value===idx
        const { shop, setOrderData, orderData } = this.props.myShopApiStore;
        let date = '';
        let from = '';
        let to = '';
        
        const {deliveryData, pickupData, isDelivery} = orderData;

        if(isDelivery){
            if(name === "deliveryTime"){
                this.setState({
                    selectedTimeIdx: value
                });

                shop.deliveryTimes.forEach((dt, idx) => {
                    if(parseInt(value) === idx) ({date, from, to} = dt);    
                })
                setOrderData('deliveryData', {...deliveryData, date, from, to})
            }
            else{
                setOrderData('deliveryData', {...deliveryData, [name]: value})
            }

            if(name === 'branch'){
                this.setState({
                    selectedBranchIdx: value
                });

                setOrderData('branch', value)
            }
        }
        else{
            if(name === "pickupTime"){
                this.setState({
                    selectedTimeIdx: value
                });

                shop.pickupTimes.forEach((pt, idx) => {
                    if(parseInt(value) === idx) ({date, from, to} = pt);
                    // { 
                    //     date = pt.date;
                    //     from = pt.from;
                    //     to = pt.to;
                    // }
                })
                setOrderData('pickupData', {...pickupData, date, from, to})
            }
            else{
                setOrderData('pickupData', {...pickupData, [name]: value})
            }

            if(name === 'branch'){
                this.setState({
                    selectedBranchIdx: value
                });

                setOrderData('branch', value)
            }
        }

        let errors = this.state.errors;
        const eIndex = errors.indexOf(name);
        if (eIndex > -1) {
            errors.splice(eIndex, 1);
            this.setState({
                errors: errors,
            });
        }
    }

    render() {
        if(this.props.currentStep !== 1){
            return null;
        } 

        const { shop, orderData, setOrderData, mainColor, resetOrderData } = this.props.myShopApiStore;
        const { t } = this.props;

        const { branches, orderTypes } = shop;
        const { deliveryData, pickupData } = orderData ?? {deliveryData: {}, pickupData: {}};

        const hasDeliveryOp = orderTypes ? orderTypes.some(ot => ot === 'delivery') : true;
        const hasPickupOp = orderTypes ? orderTypes.some(ot => ot === 'pickup') : true;
        const hasSitOp = orderTypes ? orderTypes.some(ot => ot === 'sit') : true;
        
        const isDelivery = orderData?.orderType === 'delivery';
        isDelivery !== orderData?.isDelivery && setOrderData('orderType', 'delivery'); //for sync isDelivery with orderType

        return (
            <div className={style.Container}>
                <div className={style.Title}>
                    <div>{t('We made sure you were as comfortable as possible')}</div>
                    <div>{t('How would you like to receive an order?')}</div>
                </div>
                <div className={style.UserChoosing}>
                    <div className={style.OptionBtns}>
                        {orderTypes.sort().map((item, idx)=>
                            <button key={idx} 
                                onClick={() => { 
                                    if(shop.isPriceOrderType) { //If prices depend on orderType
                                        this.setState({tryChangeTypeOrderTo: item});
                                        this.openChangeTypeOrderModal();
                                    }
                                    else {
                                        this.changeOrderType(item);
                                    }
                                }}
                                style={(orderData?.orderType === item)
                                    ? { backgroundColor: mainColor, color: "#FFFF" }
                                    : { backgroundColor: "#eeeeee", color: "#9b9a9a" }
                                }>
                                    {t(item)}
                            </button>
                        )}
                        {/* {hasDeliveryOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", true); setOrderData("orderType", 'delivery');}} style={this.optionBtnsStyle('delivery')}>משלוח</button>}
                        {hasPickupOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", false); setOrderData("orderType", 'pickup');}} style={this.optionBtnsStyle('pickup')}>איסוף עצמי</button>}
                        {hasSitOp && <button onClick={() => {this.cleanTime(); resetOrderData(); setOrderData("isDelivery", false); setOrderData("orderType", 'sit');}} style={this.optionBtnsStyle('sit')}>ישיבה במקום</button>} */}
                    </div>
                    {
                        isDelivery
                        ?
                            hasDeliveryOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                        {
                                            (branches?.length > 1 && orderData?.orderType !== 'delivery')
                                            ? 
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branch') ? '1px solid #FF0000' : 'none'}} value={this.state.selectedBranchIdx}>
                                                    <option value="">{t('From witch branch do you want take the order')}</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            :''
                                        }
                                        
                                        {
                                            !shop.withoutFuture_delivery
                                            ?
                                                <div>
                                                    <select name="deliveryTime" value={this.state.selectedTimeIdx} onChange={this.handleChange} style={{border: this.state.errors.includes('deliveryTime') ? '1px solid #FF0000' : 'none'}}>
                                                        <option value="">{t('Choose date of the delivery')}</option>
                                                        {shop.deliveryTimes.map((dt, idx) => {
                                                            return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(dt.date, dt.from, dt.to)}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={deliveryData.orderComment} onChange={this.handleChange} placeholder={t('Add notes to the order')}/>
                                            <textarea name="deliveryGuyComment" value={deliveryData.deliveryGuyComment} onChange={this.handleChange} placeholder={t('Add note for delivery person')}/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>{t('continue')}</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>{t('GoToMenu')}</button>
                                    </div>
                                </form>
                            :
                                ''
                        :
                            hasPickupOp || hasSitOp
                            ?
                                <form onSubmit={this.handleNextSubmit}>
                                    <div>
                                    {
                                        branches?.length
                                        ?
                                            <div>
                                                <select name="branch" onChange={this.handleChange} style={{border: this.state.errors.includes('branch') ? '1px solid #FF0000' : 'none'}} value={this.state.selectedBranchIdx}>
                                                    <option value="">{t('From witch branch do you want to pick up')}</option>
                                                    {shop.branches.map((branch, idx) => {
                                                        return(
                                                                <option value={branch.name} key={idx}>
                                                                    {branch.name}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        :''
                                    }
                                    
                                        {
                                            !shop.withoutFuture_pickup 
                                            ?
                                                <select name="pickupTime" value={this.state.selectedTimeIdx} onChange={this.handleChange} style={{border: this.state.errors.includes('pickupTime') ? '1px solid #FF0000' : 'none'}}>
                                                    <option value="">{
                                                        t('Choose date of the', { orderType: orderData?.orderType })
                                                    }</option>
                                                    {shop.pickupTimes.map((pt, idx) => {
                                                        return(
                                                                <option value={idx} key={idx}>
                                                                    {this.getFormatTime(pt.date, pt.from, pt.to)}
                                                                </option>
                                                        )
                                                    })}
                                                </select>
                                            :
                                                ''
                                        }
                                        <div className={style.Commands}>
                                            <textarea name="orderComment" value={pickupData.orderComment} onChange={this.handleChange} placeholder={t('Add notes to the order')}/>
                                        </div>
                                    </div>
                                    <div className={style.Btns}>
                                        <button type="submit" style={{backgroundColor: mainColor}}>{t('continue')}</button>
                                        <button type="button" onClick={() => this.props.ifaceMngr.setActiveTopMenu("ShopMenu")}>{t('GoToMenu')}</button>
                                    </div>
                                </form>
                            :
                                ''
                    }
                </div>
                <MinOrderCostModal closeModal={this.closeMinOrderCostModal} modalIsOpen={this.state.minOrderCostModalIsOpen} />
                <ChangeAddressModal closeModal={this.closeChangeAddressModal}
                                    modalIsOpen={this.state.changeAddressModalIsOpen}
                                    nextStep={this.props.nextStep}
                />
                <ChangeOrderTypeModal closeModal={this.closeChangeTypeOrderModal} 
                                        modalIsOpen={this.state.changeOrderTypeModalOpen} 
                                        nextStep={this.props.nextStep} 
                                        toType={this.state.tryChangeTypeOrderTo}
                                        changeOrderType={this.changeOrderType}/>

                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <MakeOrderErrorModal 
                    modalGoBack={false}
                    closeModal={this.closeMakeOrderErrorModal} 
                    modalIsOpen={this.state.makeOrderErrorModalIsOpen} 
                    makeOrderErrorModalErrorCode={this.state.makeOrderErrorModalErrorCode} 
                    makeOrderErrorModalErrorMessage={this.state.makeOrderErrorModalErrorMessage}
                    openBasket={this.props.openBasket}
                />
            </div>
        )
    }
}

export default withTranslation()(ShippingOptionForm);
