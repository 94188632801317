import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import style from './style.module.css';
import Modal from 'react-modal';
import APILoader from 'Components/MainScreen/AppBody/APILoader';
import ValidSMSModal from 'Components/MainScreen/AppBody/ValidSMSModal';
// import ThanksModal from 'Components/MainScreen/AppBody/ThanksModal';
// import ThanksCouponModal from 'Components/MainScreen/AppBody/ThanksCouponModal';

import ReactPixel from 'react-facebook-pixel';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class PostageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiLoaderIsOpen: false,
            smsModalIsOpen: false,
            deliveryType: null,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '260px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    preLoadApi = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId, shop} = this.props.myShopApiStore;

        const resp = await makePreorderResp();

        //console.log('p2');
        //console.log(resp);

        if(resp.data.errorCode !== '0'){
            this.props.setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
            this.props.openMakeOrderErrorModal();
            return false;
        }
        else{
            if(typeof (shop.externalTypeCodename) != 'undefined' && shop.externalTypeCodename === 'comax') {
                //
            } else {
                await createSessionResp(creditUniqueId);
            }
            return true;
        }
    }

    // prevouisly known as handleCountinueClick
    approveDelivery = async () => {
        const { closeModal, closeMapModal, nextStep } = this.props;
        const { calcTotalCartPrice, orderData, registerUser, setCreditUniqueId, setDeliveryType } = this.props.myShopApiStore;

        const cartPrice = this.state.deliveryType ? setDeliveryType(this.state.deliveryType) : calcTotalCartPrice();
        
        this.openAPILoader();
        if(orderData.isCredit && cartPrice > 0){ //payment with credit card!
            // const { createSessionResp } = this.props.myShopApiStore;
            // await createSessionResp();
            const preorder = await this.preLoadApi();
            this.closeAPILoader();
            
            closeModal(); //closing postage modal

            if(preorder) {
                closeMapModal();
                nextStep();
            } 
        }
        else{ //payment to delivery guy!
            setCreditUniqueId('');
            const token = await registerUser();
            this.closeAPILoader();
            token.data ? this.handleMakeOrderCall() :  this.openSmsModal();
        }
    }

    handleMakeOrderCall = async () => { //make order (-when the payment is to delivery guy!-)
        const { closeModal, openMakeOrderErrorModal, setMakeOrderErrorModalParams,
            openThanksModal,  closeMapModal } = this.props;
        const { makeOrderResp, deliveryCost, orderData } = this.props.myShopApiStore;

        this.openAPILoader()
        const resp = await makeOrderResp();
        //console.log("rrresp:",resp)
        this.closeAPILoader();

        closeModal(); //closing postage modal
        if(resp.data.errorCode !== '0'){
            setMakeOrderErrorModalParams(resp.data.errorCode, resp.data.errorMessage);
            openMakeOrderErrorModal();
        }
        else{
            if(process.env.REACT_APP_FACEBOOK_PIXEL) ReactPixel.track('Purchase');
            this.props.closeModal();
            closeMapModal();
            openThanksModal();
        }
    }
    
    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true});
    }

    closeSmsModal = () => {
        this.setState({smsModalIsOpen: false});
    }

    resetChosenDelivery = () => {
        const { deliveries } = this.props.myShopApiStore; 
        this.setState({deliveryType: deliveries?.length ? deliveries[0] : null})
    }

    render() {
        
        const { modalIsOpen, closeModal, distOutOfRange, t } = this.props;
        const { getShopLatLng, deliveryCost, deliveries, hasPostCoupon, shopData, orderData, userParams } = this.props.myShopApiStore;
        const { currencyName } = shopData.country;
        const brandStyles = { 
            wolt: {color: '#009DE0', logo: 'assets/delivery/WoltLogoLong.png'}, 
            yango: {color: '#FEDE47', logo: 'assets/delivery/YangoLogoLong.png'}
        }
       
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal} onAfterOpen={this.resetChosenDelivery}>
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <ValidSMSModal modalIsOpen={this.state.smsModalIsOpen} closeModal={this.closeSmsModal}
                                handleMakeOrderCall={this.handleMakeOrderCall} action='Delivery' nextStep={this.props.nextStep}
                />
                {/* { hasPostCoupon
                    ? <ThanksCouponModal closeModal={this.props.closeThanksModal} modalIsOpen={this.props.thanksModalIsOpen} />
                    : <ThanksModal closeModal={this.props.closeThanksModal} modalIsOpen={this.props.thanksModalIsOpen} />
                } */}

                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    { (orderData.isCredit || userParams.email.includes('@my-shop.co.il')) 
                        && deliveries?.filter(d => d.codename !== 'self').length  
                        ? <div className={style.TextContnet}>
                            <h3 className={style.Title} style={{margin: '0'}}>{t('the delivery method')}</h3>
                            <h4 className={style.TowRowText}>{t('please choose a delivery method')}</h4>
                            <ul>
                                {
                                    deliveries.map((d, index) => {
                                        return (
                                            <li key={index} onClick={() => this.setState({deliveryType: d})}>
                                                <div> 
                                                    { brandStyles[d.codename]?.logo 
                                                        ? <img src={brandStyles[d.codename]?.logo} alt={ d.name } />
                                                        : <div>{ d.name }</div>  
                                                    }    
                                                    <input type="radio" name="delivery" value={ d.codename }  id={`delivery_${index}`} 
                                                        checked={this.state.deliveryType?.codename === d.codename}
                                                        onChange={() => this.setState({deliveryType: d})}
                                                     /> 
                                                
                                                    
                                                </div>
                                                
                                                {/*!!shop.withoutFuture_delivery &&*/
                                                    <div style={{flex: '2 0 100px', textAlign: 'end'}}>
                                                        {!!d.timeFrom && t('Period of time', {from: d.timeFrom?.toFixed(0), to: d.timeTo?.toFixed(0), context: (d.timeTo ? 'fromTo' : 'from')})}
                                                    </div>}
                                                <div style={{flex: '1 1 1px'}}> | </div>
                                                <div style={{textAlign: 'end'/*document.body.dir==='rtl' ? 'left' : 'right'*/}}> 
                                                    {!d.price ? t('gratis') : d.price + ' ' + currencyName}
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        : <div className={style.TextContnet}>
                            <div className={style.Title}>
                                <span>{t('pay attention')}</span>
                            </div>
                            <div className={style.TowRowText}>
                                {
                                    distOutOfRange
                                    ?
                                        <>
                                            <span>{t('delivery') + ' ' + t('can not be made')}</span>
                                            <span>{t('for requested address')}</span>
                                            {!getShopLatLng() && <span style={{color: '#FF0000', fontSize: '12px', fontWeight: '600'}}> {t('your address can not be found')}</span>}
                                        </>
                                    :
                                        <>
                                            <span>{t('delivery cost to your address')}</span>
                                            <span>{t('that ordered')}</span>
                                        </>
                                }
                            </div>
                        
                            <div className={style.Price}>
                            {
                                distOutOfRange
                                ?
                                    <>
                                        <span style={{color: '#FFFFFF'}}>.</span>
                                        <span style={{color: '#FFFFFF'}}>.</span>
                                    </>
                                :   (deliveryCost === 0
                                        ? <><span>{t('free')}</span><span></span></>
                                        : <><span>{ currencyName }</span><span>{deliveryCost.toFixed(2)}</span></>)
                            }
                            </div>
                        </div>
                    }
                    <div className={style.ContinueBtn}>
                        <span onClick={distOutOfRange ? closeModal : this.approveDelivery}>{distOutOfRange ? t('back') : t('continue')}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(PostageModal);