import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import style from "./style.module.css";
import NumberFormat from "react-number-format";
import ShippingOptionForm from "./ShippingOptionForm";
import PaymentOptionForm from "./PaymentOptionForm";
import CreditDetailsForm from "./CreditDetailsForm";
import SimplePayOpForm from "./SimplePayOpForm";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class Payment extends React.Component {
    constructor(props) {
        super(props)    
        this.state = {
             isShippingOption: !this.props.myShopApiStore.orderData || this.props.myShopApiStore.orderData.isDelivery,
             currentStep: 1
            //  isPaymentForm: false
        }
    }
    
    componentDidMount = () => {
        this.props.myShopApiStore.setOtherPaymentType('')
        this.props.myShopApiStore.setCardNumber('')
    }
    

    setShippingOption = (shippingOption) => {
        this.setState({isShippingOption: shippingOption});
    }
   
    nextStep = () => {
        if(this.state.currentStep < 3){
            this.setState({currentStep: this.state.currentStep + 1});

        }
    }

    prevStep = () => {
        //reset date,from,to of deliveryData & pickupData---------------
        if(this.state.currentStep === 2){
            const { deliveryData, pickupData } = this.props.myShopApiStore.orderData;
            this.props.myShopApiStore.setOrderData('deliveryData', {...deliveryData, date: '', from: '', to: ''})
            this.props.myShopApiStore.setOrderData('pickupData', {...pickupData, date: '', from: '', to: ''})
        }
        //-----
        
        if(this.state.currentStep > 1){
            this.setState({currentStep: this.state.currentStep - 1});
        }
    }
    render() {
        const { shopData, mainColor, shop, cartPrice } = this.props.myShopApiStore;   
        const { currencyName } = shopData.country;
        const { t } = this.props;     
        return (
            <div className={style.Container}>
                {
                    !shop.isBusiness &&
                        <div className={style.PriceInfo} style={{backgroundColor: mainColor}}>
                            <div className={style.PriceTitle}>{t('total amount') + ' '}
                                <NumberFormat
                                    value={cartPrice}
                                    prefix={ currencyName }
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                />
                            </div>
                            <div className={style.PriceDescription}>
                            {shopData.paymentDescription}
                            </div>
                        </div>
                }
                {/* <div>{this.renderForm()}</div> */}
                <ShippingOptionForm 
                        setShippingOption={this.setShippingOption}
                        currentStep={this.state.currentStep}
                        nextStep={this.nextStep}
                        openBasket={this.props.openBasket}
                />
                {
                    this.state.isShippingOption
                        ? <PaymentOptionForm openBasket={this.props.openBasket} currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/>
                        : <SimplePayOpForm openBasket={this.props.openBasket} currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/>
                }
            
                <CreditDetailsForm openBasket={this.props.openBasket} currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/>
            </div>
        )
    }
}

export default withTranslation()(Payment);
