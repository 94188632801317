import React from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import imgBasket from 'assets/blue_basket_x2.png'

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class Empty extends React.Component {
    
    componentDidMount = () => {
        this.props.hideBasket();
    }

    render() {
        const { shop } = this.props.myShopApiStore;
        const { t } = this.props;

        return (
            <div className={style.container} /*style={{'--mainColor': shop.mainColor}}*/>
                <div id="" className={style.TopDiv}>
                    <div className={style.Desc}>{t('You have no previous orders')}</div>
                    <button className={style.btn}
                    onClick={() => this.props.ifaceMngr.setActiveTopMenu('ShopMenu')} 
                    ><div className={style.btnDesc}>{t("Click on basket and let's get started")}</div>
                        <img className={style.imgBasket} src={imgBasket} alt={t("Your basket is empty")}/>
                    </button>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Empty);