import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import { withTranslation } from 'react-i18next';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class MakeOrderErrorModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '260px',
                maxHeight: '300px',
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }
    
    handleCloseClick = () => {
        if(this.props.makeOrderErrorModalErrorCode.search('COUPON')>=0) {
            this.props.myShopApiStore.clearCoupon();
        }
        if(this.props.modalGoBack || this.props.makeOrderErrorModalErrorCode.search('COUPON')>=0) {
            this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
            this.props.openBasket();
        }
        this.props.closeModal();
    }

    render() {
        const { modalIsOpen, closeModal, makeOrderErrorModalErrorCode, makeOrderErrorModalErrorMessage, t } = this.props;
        const { orderData } = this.props.myShopApiStore;
        const orderTypeName = ['delivery', 'pickup'].includes(orderData.orderType) 
            ? t(orderData.orderType) : t('inhouse');

        const modalText1 = 
                            makeOrderErrorModalErrorCode.search('COUPON')>=0 ? 
                                makeOrderErrorModalErrorMessage 
                            : 
                                makeOrderErrorModalErrorCode === 'DATE_ERROR' ? 
                                    t('can not be made') + ' ' + t('order')
                                :
                                    t('Server error!')        ;

        const modalText2 = 
                            makeOrderErrorModalErrorCode.search('COUPON')>=0 ?
                                t('coupon canceled')
                            : 
                                makeOrderErrorModalErrorCode === 'DATE_ERROR' ?
                                    orderTypeName + ' ' + t('for now')
                                : 
                                    makeOrderErrorModalErrorMessage
        ;

        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleCloseClick}>
                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>{t('pay attention')}</span>
                        </div>
                        <div className={style.TowRowText}>
                            <span>{modalText1}</span>
                            <span>{modalText2}</span>
                        </div>
                    </div>
                    <div className={style.ContinueBtn}>
                        <span onClick={this.handleCloseClick}>{t('back')}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withTranslation()(MakeOrderErrorModal);